.p-dropdown:not(.filter) {
  @extend .p-inputtext;
  padding: 0;
  width: 100%;
  .p-dropdown-items {
    padding: 0;
  }
}

.p-dropdown-panel {
  @include dropdown-panel('.p-dropdown');
  .p-dropdown-items {
    .p-dropdown-item {
      padding: rem(4) rem(8);
      .p-icon-wrapper {
        display: none;
      }
    }

    .p-dropdown-item-group {
      padding: rem(4) rem(8);
    }

    .p-dropdown-empty-message {
      padding: 0 !important;
    }
  }

  .p-dropdown-header {
    padding: 0;
    background: transparent;
  }
}

// filters from table
.p-dropdown {
  &.filter {
    width: 100%;
    @include filter-input('.p-dropdown');

    @media (max-width: $desktop) {
      p-iconfield, .p-inputtext {
        width: 100%;
      }
    }
    &.p-inputwrapper-filled {
      background: $lightPurple;
      .p-inputtext {
        font-weight: $fontWeightItemFilter;
        @media (max-width: $desktop) { 
          &.p-dropdown-label {
            width: calc($mobileSelectedFilterValue - 10vw);
          }
        }
      }
  
      .p-dropdown-clear-icon,
      .icon-arrow {
        color: $darkPurple2;
      }

      .p-dropdown-clear-icon {
        top: rem(15);
      }
    }

    &.filter-sort {
      .icon-arrow-sort {
        transform: rotate(180deg);
      }
    }

    .p-dropdown-item-group {
      button {
        color: $purpleGray2;
        padding: 0;
      }
    }
  }
}
