.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      border: none !important;
    }

    .p-icon {
      width: 1em;
      height: 1em;
    }
  }

  &.p-checkbox-disabled {
    .p-checkbox-box {
      border-color: $disabledGray;
      &.p-highlight {
        background: $disabledGray;
      }
    }
  }
}