.p-button {
  font-weight: 500;

  &.p-button-primary:not(.p-button-primary-error) {
    background: $primaryGradient;
    border: none;
    padding: calc(#{$buttonPaddingY} + 1px) calc(#{$buttonPaddingX} + 1px);
  }

  &.p-button-primary-error {
    background: $danger;
    border-color: $danger;
  }

  &.p-button-secondary:not(.p-button-secondary-error) {
    color: $primaryColor;
  }

  &.p-button-secondary {
    border-color: currentColor;
    background: $white;
    &.p-button-outlined:not(:disabled):hover {
      background: $white;
    }
  }

  &.p-button-secondary-error {
    color: $danger;
  }

  &.square {
    width: $btnSquareSize;
    height: $btnSquareSize;
    border-radius: $radiiMd;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &[disabled] {
      svg {
        opacity: $buttonStatesOpacity;
      }
    }
  }

  &.custom-link {
    color: $darkPurple2 !important; // temporary solution
    text-decoration: none;
  }

  &.p-button-sm {
    font-weight: 400;
    font-size: rem(12);
    padding-left: rem(12);
    padding-right: rem(12);
  }

  // states
  &:focus-visible {
    outline: 2px solid $focusVisibleColor;
  } 

  &:hover {
    opacity: $buttonStatesOpacity;
  }
}

// link 
a {
  &.p-button { 
    color: $primaryTextColor !important; // temporary solution
  }
}