.p-inputmask {
  &.ng-dirty.ng-invalid {
    border-color: $danger;
    &:enabled {
      &:hover, 
      &:focus {
        border-color: $danger;
      }
    }
  }
}