.p-toast {
  .p-toast-message {
    .p-toast-message-content {
      display: flex;
      align-items: center;
      .p-toast-detail {
        font-weight: 400;
        width: 92%;
        line-height: 1.2;
      }

      .p-toast-message-icon {
        font-size: 0;
      }

      .p-toast-icon-close {
        position: absolute;
        right: $toastPaddingX;
        top: $toastPaddingY;
        &:hover {
          background: transparent;
          opacity: 0.5;
        }

        &:focus-visible {
          outline: 2px solid $focusVisibleColor;
        }
      }
    }
  }
}
