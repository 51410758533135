.p-tabview {
  .p-tabview-nav {
    li {
      &.p-highlight {
        .p-tabview-nav-link {
          border-width: 0 0 3px 0;
          margin: 0;
          color: $primaryColor !important;
        }
      }
      &:not(.p-highlight) {
        .p-tabview-nav-link {
          border-color: transparent;
          color: $purpleGray !important;
        }
      }

      .p-tabview-nav-link { 
        font-weight: 400;
        padding: rem(8) rem(12);
      }
    }

    &-btn {
      &.p-link {
        border-radius: 0;
        color: $darkPurple2;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        box-shadow: 0px 0px 10px 50px rgba(255, 255, 255, 0.6);
      }
    }
  }
}
