.p-timeline {
  display: grid;
  justify-content: flex-start;
  overflow-x: auto;
  .p-timeline-event {
    min-height: rem(25);
    &:last-child {
      .timeline-line {
        display: none;
      }
    }

    &:not(:last-child) {
      .timeline-wrapper {
        grid-template-columns: auto minmax(rem(40), rem(120));
      }
    }
  }

  .p-timeline-event-connector {
    display: none;
  }

  .p-timeline-event-separator {
    position: absolute;
    top: 5px;
  } 

  .p-timeline-event-content {
    font-size: rem(12);
    font-weight: 400;
    min-width: auto;
    padding: 0;
  }

  .p-timeline-event-opposite {
    display: none;
  }

  .p-timeline-event-marker {
    position: relative;
    z-index: 1;
    left: rem(4);
    flex-shrink: 0;
    .timeline-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.6em;
      width: 100%;
    }
  }

  .timeline-content {
    background-color: $lightPurple3;
    color: $darkPurple3;
    border-radius: $radii2Lg;
    padding: rem(4) rem(8) rem(4) rem(22);
    line-height: 1.3;
    white-space: nowrap;
  }

  .timeline-wrapper {
    display: grid;
    align-items: center;
  }

  .timeline-line {
    height: 3px;
    background: $lightPurple3;
  }
}