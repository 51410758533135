.p-tieredmenu {
  border-radius: 0;
  padding: 0;
  width: 100%;
  .p-tieredmenu-overlay {
    border-bottom: 1px solid $lightGray;
    border-right: 1px solid $lightGray;
    border-left: 1px solid $lightGray;
    box-shadow: none;
    margin-top: rem(-1);
    margin-left: rem(-1);
  }

  .p-menuitem > .p-menuitem-content {
    .p-menuitem-link {
      border-top: 1px solid $lightGray;
      word-break: break-all;
      white-space: normal;
      padding: rem(8) rem(8);
      color: $purpleGray2 !important; // temporary solution 
      font-size: $fontSizeItemFilter;
      font-weight: 400;
      &:hover {
        background: $lightPurple2;
        color: $darkPurple2 !important; // temporary solution 
      }
    }
  }
}