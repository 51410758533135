/* Customizations to the designer theme should be defined here */
// fonts
$fontFamily: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$fontSize: rem(16);
$buttonPaddingY: rem(4);
$buttonPaddingX:rem(20);
$buttonPadding: $buttonPaddingY $buttonPaddingX;
$fontWeight: 500;

// common
$disabledOpacity: 1;
$highlightBg: $lightPurple2;
$highlightTextColor: $darkPurple2;

// sizes
$borderRadius: $radiiSm;

// colors
$dangerButtonBg: $danger;
$warningButtonBg: $warning2;

//buttons
$infoButtonBg: $lightPurple;
$infoButtonBorder: $lightPurple;
$infoButtonTextColor: $darkPurple2;
$infoButtonHoverBg: $lightPurple;
$infoButtonTextHoverColor: $darkPurple2;
$infoButtonHoverBorderColor: $lightPurple;
$infoButtonActiveBg: $lightPurple;
$infoButtonTextActiveColor: $darkPurple2;
$infoButtonActiveBorderColor: $lightPurple;
$infoButtonFocusShadow: none;
$secondaryButtonFocusShadow: none;

// focus
$inputFocusBorderColor: $darkPurple2;
$focusShadow: none;
$focusOutline: none;

// badge
$badgeFontSize: rem(12);
$badgeHeight: rem(18);
$badgeMinWidth: rem(18);
$badgeTextColor: $white;

// toolbar
$panelHeaderBg: transparent;
$panelHeaderPadding: 0;
$panelHeaderBorder: 1px solid transparent;
$panelHeaderPadding: $panelHeaderPdY $panelHeaderPdX;
$panelToggleableHeaderPadding: $panelHeaderPdY $panelHeaderPdX;

// avatar
$avatarBg: $white;
$avatarTextColor: $primaryColor;

// card
$cardShadow: none;
$cardBodyPadding: rem(16) rem(24);
$cardContentPadding: 0;

// inputs
$inputTextColor: $darkPurple2;
$inputPadding: rem(3) rem(8);
$inputBorder: 1px solid $lightGray;
$inputHoverBorderColor: $darkPurple2;

// panel
$panelContentBorder: none;

// tabview
$tabviewNavBorder: 1px solid $lightGray;
$tabviewNavBorderWidth: 0 0 1px 0;
$tabviewHeaderTextActiveColor: $primaryColor;
$tabviewHeaderTextColor: $purpleGray;
$tabviewContentPadding: 0;

// calendar
$calendarHeaderTextColor: $primaryColor;
$calendarHeaderFontWeight: 500;
$calendarCellDateTodayBg: $primaryColor;
$calendarCellDateTodayTextColor: $defaultWhite;
$calendarPadding: 0;
$calendarHeaderBorder: 1px solid $lightGray;
$calendarHeaderCellPadding: 0;
$calendarCellDateWidth: rem(32);
$calendarCellDateHeight: rem(32);
$calendarTextColor: $darkPurple2;
$calendarTextColor: $darkPurple2;

// tooltip
$tooltipBg: $defaultWhite;
$tooltipTextColor: $darkPurple2;
$tooltipPadding: rem(10) rem(14);

// checkbox
$checkboxWidth: rem(18);
$checkboxHeight: rem(18);
$checkboxBorder: 1px solid $primaryColor;
$checkboxActiveHoverBg: $primaryGradient;
$checkboxActiveBg: $primaryGradient;
$checkboxActiveBorderColor: transparent;

// radio
$radiobuttonWidth: rem(18);
$radiobuttonHeight: rem(18);
$radiobuttonBorder: 1px solid $primaryColor;
$radiobuttonActiveHoverBg: transparent;
$radiobuttonActiveBg: transparent;
$radiobuttonActiveBorderColor: $primaryColor;

// card
$cardShadow: none;
$cardBodyPadding: rem(16) rem(24);
$cardContentPadding: 0;
$panelContentTextColor: $black2;

// inputs
$inputTextColor: $darkPurple2;
$inputPadding: rem(3) rem(8);
$inputBorder: 1px solid $lightGray;

// chip
$chipBg: $lightPurple2;
$chipTextColor: $darkPurple3;
$chipBorderRadius: $radiiXl;

// table
$tableBodyCellBorder: none;
$tableHeaderCellBg: $lightPurple2;
$tableHeaderCellTextColor: $darkPurple3;
$tableHeaderCellFontWeight: 400;
$tableBodyRowTextColor: $darkPurple2;
$tableBodyCellPadding: rem(8) rem(10);
$tableHeaderCellPadding: rem(16) rem(10);
$tableHeaderBg: transparent;
$tableHeaderBorder: none;
$tableHeaderFontWeight: 400;
$tableHeaderPadding: 0 0;

// paginator
$paginatorTextColor: $purpleGray2;
$paginatorElementHeight: rem(32);
$paginatorElementWidth: rem(32);
$paginatorElementHoverBg: $lightPurple2;
$paginatorElementHoverBorderColor: $lightPurple2;
$paginatorElementMargin: rem(4);
$paginatorBorder: none;
$paginatorElementBorderRadius: $radiiPaginator;

// toast
$toastPaddingX: rem(16);
$toastPaddingY: rem(12);
$toastPadding: $toastPaddingY $toastPaddingX;
$toastDetailMargin: 0;
$toastIconFontSize: rem(18);
$toastOpacity: 1;
$errorMessageTextColor: $darkPurple2;
$errorMessageIconColor: $darkPurple2;
$errorMessageBg: $danger2;
$warningMessageTextColor: $darkPurple2;
$warningMessageIconColor: $darkPurple2;
$warningMessageBg: $warning3;
$successMessageTextColor: $darkPurple2;
$successMessageIconColor: $darkPurple2;
$successMessageBg: $success2;

// timeline 
$timelineEventMarkerBorder: 1px solid currentColor;
$timelineEventMarkerBackground: transparent;
$timelineEventMarkerWidth: rem(13);
$timelineEventMarkerHeight: rem(13);
$timelineEventColor: $lightGray;
$timelineEventConnectorSize: rem(3);

// dialog
$dialogHeaderBorder: none;
$dialogHeaderFontSize: rem(18);
$dialogHeaderFontWeight: 500;
$dialogHeaderPadding: $modalPd $modalPd 1rem $modalPd;
$dialogContentPadding: 0 $modalPd;
$dialogFooterPadding: $modalPd;
$dialogFooterBorder: none;

// message
$messagePaddingX: rem(10);
$messagePaddingY: rem(8);
$messagePadding: $messagePaddingY $messagePaddingX;
