// for filter input type text
.filter-panel {
  .p-overlaypanel-content {
    padding: 0;
  }

  &.p-overlaypanel {
    margin-top: 0;
    &:after {
      content: none;
    }
    &:before {
      content: none;
    }
  }

  @media (max-width: $desktop) {
    width: calc(100% - $sidebarWidth - ($containerPdDesktop * 2) - ($defaultPdX * 2));
    .p-inputtext {
      width: 100%;
    }
  }

  @media (max-width: $tablet) {
    width: calc(100% - ($containerPdTablet * 2));
  }
}