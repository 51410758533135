.p-paginator {
  margin-top: rem(20);
  .p-paginator-pages  {
    .p-paginator-page {
      border-radius: $radiiPaginator;
      &.p-link {
        border-radius: $radiiPaginator;
        font-size: rem(14);
      }

      &.p-highlight  {
        font-weight: 500;
      }
    }
  }
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    border-radius: $radiiPaginator;
    &.p-link {
      border-radius: $radiiPaginator;
    }
    &:not(.p-disabled):not(.p-highlight):hover { 
      border-radius: $radiiPaginator;
      &.p-link {
        border-radius: $radiiPaginator;
      }
    }
  }
}

.p-paginator-icon {
  color: $primaryColor;
}