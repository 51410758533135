.p-card {
  &.card-secondary, &.card-empty {
    background-color: $lightPurple2;
    border-radius: 0;
  }

  &.card-secondary {
    border: 1px solid $lightGray;

    .p-card-body {
      padding: $cardSecondaryPdY $cardSecondaryPdX;
    }
  }

  &.card-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $purpleGray2;
    font-weight: 400;
    font-size: rem(14);
  }
}

.card-list {
  display: flex;
  flex-direction: column;
  gap: rem(7);
  font-size: rem(14);
  padding-left: 0;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: rem(10);
}

.card-item-text {
  color: $purpleGray2;
  display: block;
  margin-right: rem(4);
  flex-shrink: 0;
}

.card-title {
  @media (min-width: calc($desktop + 1px)) {
    margin-bottom: rem(20);
  }
}