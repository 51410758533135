.p-splitbutton {
  position: relative;
  &:hover {
    .p-button {
      &:first-of-type {
        opacity: $buttonStatesOpacity;
      }
    }
  }

  .p-splitbutton-menubutton {
    &[aria-expanded="true"] {
      .p-icon {
        transform: rotate(180deg);
      }
    }
  }

  .p-button {
    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      padding-right: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      position: static;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.p-splitbutton-secondary-error {
    .p-button { 
      color: $danger;
    }
  } 
}