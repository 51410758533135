@use '../themes/azz-theme/theme.scss' as *;

p {
  line-height: 1.2;
}

h2 {
  font-size: rem(18);
  font-weight: 500;
  color: $darkPurple2;
}

.link {
  font-weight: 500;
  color: $primaryColor !important; // !important - temporary solution, delete it after all refactoring
  text-decoration: none;
  &:hover {
    opacity: $buttonStatesOpacity;
  }
}