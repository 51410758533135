.p-datatable {
  > .p-datatable-wrapper {
    border: 1px solid $lightGray;
    border-top-right-radius: $radiiSm;
    border-top-left-radius: $radiiSm;
  }

  .p-datatable-table {
    table-layout: fixed;
    .table-groupheader {
      background: $lightPurple2;
      border-radius: $radiiXl;
      padding: rem(4) rem(10);
      color: $primaryBlue;
      position: relative;
      z-index: 99;
    }
  }

  .p-datatable-thead {
    border-bottom: 1px solid $lightGray;
    > tr {
      > th {
        border: none;
        font-size: rem(14);
      }
    }
  }

  .p-datatable-tbody {
    > tr {
      border-top: 1px solid $lightGray;
      > td {
        word-wrap: break-word;
        font-weight: 400;
        font-size: rem(14);
        .table-empty-td {
          font-size: rem(16);
          text-align: center;
          padding-top: rem(43);
          padding-bottom: rem(43);
          width: 100%;
        }
      }
      &:not(.p-rowgroup-header, .table-empty-tr) {
        &:hover {
          background: $lightPurple2;
          cursor: pointer;
        }
        > td {
          @media (max-width: $desktop) { 
            &:last-child {
              border-bottom: 1px solid $lightGray;
            }
          }
        }
      }

      &.p-rowgroup-header {
        border: none;
        top: 52px !important;
        @media (max-width: $desktop) { 
          top: 0 !important;
        }
        td {
          &:after {
            content: '';
            height: 1px;
            position: absolute;
            left: 0;
            right: 0;
            top: rem(18);
            background: $primaryBlue;
          }
        }

        + tr {
          border-top: none;
        }
      }
    }
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: $darkPurple3;
      margin-left: 0;
      margin-right: rem(8);
    }

    &.p-highlight {
      background: $tableHeaderCellBg;
      color: $darkPurple3;
      .p-sortable-column-icon {
        color: $primaryColor;
      }
    }
  }

  .p-datatable-loading-overlay {
    &.p-component-overlay {
      background-color: rgba($white, 0.6);
    }
  }

  .p-datatable-header {
    margin-bottom: rem(15);
  }

  .p-column-title {
    width: rem(120);
    flex-shrink: 0;
    @media (min-width: calc($desktop + 1px)) { 
      display: none;
    }
  }
}