.p-panel {
  .p-panel-header {
    background: $defaultWhite;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    gap: $gapMd;
    .p-panel-header-icon {
      background: $lightPurple;
      width: rem(20);
      height: rem(20);
      color: $darkPurple2;
      border-radius: 50%;
    }
  }
}