@mixin base-container($padding: 0px) {
  max-width: calc($maxWidthContainer + #{$padding});
  width: 100%;
}

@mixin transition($properties) {
  transition: $properties ease 0.3s;
}

@mixin text-overflow($width) {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: $width;
  overflow: hidden;
}

@mixin line-clamp($string) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: $string;
  -webkit-line-clamp: $string;
  overflow: hidden;
}
