$badgeHeightLg: rem(16);

.p-badge {
  font-size: 0.75em !important;
  color: $defaultWhite;

  &.p-badge-info {
    color: $darkPurple;
    font-weight: 400;
  }

  &.p-badge-lg {
    border-radius: $radiiLg;
    padding-left: rem(10);
    padding-right: rem(10);
    height: 1.5 * $badgeHeightLg;
    line-height: 1.5 * $badgeHeightLg;
  }
}