// filter input for tables
@mixin filter-input($thisClass) {
  background: $lightPurple2;
  border-radius: $radiiXl;
  border-color: $lightPurple2;
  padding: rem(3) 0;

  #{$thisClass}-trigger {
    height: rem(17);
  }

  #{$thisClass}-clear-icon,
  .icon-arrow {
    color: $primaryBlue;
    width: $sizeIconFilter;
    height: $sizeIconFilter;
  }

  #{$thisClass}-label {
    font-size: $fontSizeLabelFilter;
    line-height: 1;
  }

  #{$thisClass}-items {
    padding-top: 0;
    &.p-highlight {
      background: transparent;
      color: $primaryDarkColor;
    }
  }

  .p-overlay {
    min-width: rem(220) !important;
  }

  &#{$thisClass}-open {
    .icon-arrow {
      transform: rotate(180deg);
    }
  }
}

// dropdown and multiselect panel 
@mixin dropdown-panel($thisClass) {
  border-radius: 0;
  border-bottom: 1px solid $lightGray;
  border-right: 1px solid $lightGray;
  border-left: 1px solid $lightGray;
  margin-top: rem(-1);
  margin-left: rem(-1);

  #{$thisClass}-items {
    padding-bottom: 0;
    #{$thisClass}-item,
    #{$thisClass}-item-group {
      color: $purpleGray2;
      font-size: $fontSizeItemFilter;
      border-top: 1px solid $lightGray;
      word-break: break-all;
      white-space: normal;

      .p-highlight {
        color: $primaryDarkColor;
      }

      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
          background: $lightPurple2;
          color: $primaryDarkColor;
        }
      }

      &.p-highlight.p-focus {
        background: $lightPurple2;
        color: $primaryDarkColor;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        background: $lightPurple2;
        color: $darkPurple2;
      }
    }
  }
}

@mixin filter-label {
  color: $primaryBlue;
  font-size: $fontSizeLabelFilter;
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;
}

@mixin filter-label-selected($topPos: rem(-11)) {
  color: $purpleGray2;
  top: $topPos;
}