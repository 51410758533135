%error-input {
  border-color: $danger;
  &:enabled {
    &:hover, 
    &:focus {
      border-color: $danger;
    }
  }
}

%label {
  display: block;
  font-weight: 400;
  &:not(.label-checkbox) {
    margin-bottom: $labelMb;
    font-size: rem(12);
    color: $darkPurple3;
  }
}

%empty {
  padding: .5rem 1rem;
  background: $lightPurple2;
  .empty-text {
    font-size: rem(14);
    font-weight: 400;
    color: $purpleGray;
  }
  
  .empty-btn {
    font-weight: 500;
    color: $primaryColor;
  }
}

// manual driver status, client status
%session-status {
  border-radius: 50%;
  display: block;
  flex-shrink: 0;

  &:not(.session-status-sm) {
    width: $sessionStatusSize;
    height: $sessionStatusSize;
    margin-right: rem(8)
  }
  
  &.session-status-sm {
    width: $sessionStatusSizeSm;
    height: $sessionStatusSizeSm;
  }

  &.free, &.connected {
    background-color: $success;
  }

  &.occupied, &.confirmed,
  &.in-queue, &.at-departure-address {
    background-color: $danger;
  }

  &.unreachable {
    background-color: $lightGray3;
  }

  &.towards-destination {
    background-color: $warning2;
  }
}

%layout-dash {
  background: $white;
  width: 100%;
  padding: $containerPdDesktop;

  @media (max-width: calc($tablet - 1px)) {
    padding: $containerPdTablet;
  }
}
