.p-datepicker {
  max-width: $datepickerWidth;
  border-radius: 0;
  width: $datepickerWidth;
  position: absolute;
  right: inherit !important;
  top: rem(30) !important;
  left: 0 !important;
  @media (max-width: calc($mobile - 1px)) {
    left: -33vw !important;
    width: $datepickerMobileWidth;
  }

  &.p-datepicker-multiple-month {
    width: $datepickerWidth * 2;
    max-width: $datepickerWidth * 2;
  }

  .p-disabled, .p-component:disabled {
    opacity: 1;
    color: $lightGray3;
  }

  table {
    font-size: rem(14);
    thead {
      text-align: center;
    }

    td > span {
      font-weight: 400;
      &.p-highlight {
        color: $primaryColor;
        background: $lightPurple2;
      }
    }

    td.p-datepicker-today > span {
      font-weight: 500;
      &:hover {
        color: $primaryColor;
      }
    }
  }

  .p-datepicker-header {
    .p-datepicker-prev:enabled:hover,
    .p-datepicker-next:enabled:hover {
      background: $lightPurple2;
      color: $primaryColor;
    }

    .p-datepicker-next,
    .p-datepicker-prev {
      width: rem(24);
      height: rem(32);
      color: $purpleGray;
    }
  }
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: none;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: $lightPurple2;
}

.calendar-only-icon {
  .p-inputtext{
    display: none;
  }
}
