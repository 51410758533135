@use 'sass:math';

// table filters
.p-column-filter {
  display: flex;  
  &-buttonbar {
    gap: rem(24);
    justify-content: flex-end;
    .p-button {
      width: auto;
      &.p-button-sm {
        padding: $buttonPaddingY $buttonPaddingX;
        font-size: $fontSize;
        font-weight: $fontWeight;
      }
      
      &:not(.p-button-outlined) {
        background: $primaryGradient;
        border: none;
        padding: calc(#{$buttonPaddingY} + 1px) calc(#{$buttonPaddingX} + 1px);
      }
    }
  }
}

.p-column-filter-menu-button {
  background-color: transparent;
  width: 100%;
  height: auto;

  &-open {
    .icon-arrow {
      transform: rotate(180deg);
    }
  }
}

.p-column-filter-overlay-menu {
  .p-datepicker {
    position: static;
  }
}

// with panel component
// type filter - input text
.filter-input-text {
  position: relative;
  width: 100%;
  @media (max-width: $desktop) { 
    min-width: 100%;
  }
  .filter-btn {
    display: flex;
    justify-content: space-between;
    background: $lightPurple2;
    border-radius: $radiiXl;
    border-color: $lightPurple2;
    padding: rem(3) rem(10);
    color: $primaryBlue;
    width: 100%;
    font-size: $fontSizeLabelFilter;
    .p-ink {
      display: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  .close-btn {
    position: absolute;
    z-index: 999;
    top: 0;
    right: rem(30);
    .icon-close {
      color: $darkPurple2;
    }
  }

  .filter-label {
    @include filter-label;
    @include text-overflow(100%);
    transition: top ease $transitionDuration;
    top: 0;
    position: relative;
  }

  .filter-icon {
    .icon-arrow {
      width: $sizeIconFilter;
    }

    &-open {
      .icon-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &.filter-selected {
    .filter-label {
      @include filter-label-selected(rem(-22));
      left: 0;
      position: absolute;
      z-index: 1;
      transition: top ease $transitionDuration;
    }

    .filter-btn {
      color: $darkPurple2;
      background: $lightPurple;
      &-selected {
        @include text-overflow(100%);
        max-width: rem(140);
        text-align: left;
        font-weight: $fontWeightItemFilter;
        padding-right: nth($inputPadding, 2) + $primeIconFontSize + rem(12);
        @media (max-width: $desktop) { 
          max-width: $mobileSelectedFilterValue;
        }
      }
    }
  }
}