.p-radiobutton {
  .p-radiobutton-box {
    .p-radiobutton-icon {
      background: $primaryGradient;
    }
    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: $primaryColor;
    }
  }

  &.p-radiobutton-disabled {
    .p-radiobutton-box {
      border-color: $disabledGray;
      .p-radiobutton-icon {
        background: $disabledGray;
      }
    }
  }
}