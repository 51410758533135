.p-autocomplete-panel {
  border-radius: 0;
  color: $darkPurple2;
  box-shadow: 0px 9px 13px 0px rgba($black, 9%);
  border: 1px solid $lightGray;
  .p-autocomplete-items {
    padding: 0;
    .p-autocomplete-item {
      padding: rem(5) rem(8);
      color: inherit;
      + .p-autocomplete-item {
        border-top: 1px solid $lightGray;
      }
      &:not(.p-highlight):not(.p-disabled):hover {
        background: $lightPurple2;
      }

      &.p-highlight {
        background: $lightPurple2;
      }

      &.p-disabled {
        background: $disabledGray;
        opacity: 1;
        color: $lightGray3;
      }
    }
  }
}

.p-overlay {
  width: 100%;
}

p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext {
  border-color: $danger;
}

.p-autocomplete, .p-autocomplete-input {
  width: 100%;
}

.p-autocomplete-input {
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-autocomplete-empty-message {
  @extend %empty;
}

// autocomplete with dropdown
.p-autocomplete-dropdown {
  &.p-button.p-button-icon-only {
    width: auto;
    padding: 0;
    background: transparent;
    color: $inputIconColor;
    width: $inputGroupAddOnMinWidth;
    border-radius: 0;
    border: none;
  }
}

.dropdown-autocomplete {
  input {
    padding-right: rem(70);
  }
  .p-autocomplete {
    position: relative;
    .p-autocomplete-dropdown {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
    }
  }

  .p-autocomplete-clear-icon {
    position: absolute;
    right: $inputGroupAddOnMinWidth;
    top: rem(8);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}