.p-inputtext {
  border-radius: 0;
  &.p-component {
    font-weight: 400;
  }

  &.ng-dirty.ng-invalid {
    @extend %error-input;
  }

  &:enabled {
    &:hover, 
    &:focus {
      border-color: $darkPurple2;
    }
  }
  
  &[disabled] {
    background: $disabledGray;
    color: $lightGray3;
  }
}

.p-icon-field .p-input-icon {
  top: 0;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

// float label for filter
.p-float-label > .filter-label {
  top: rem(14);
  @include filter-label;
}

.p-inputwrapper-filled {
  ~ .filter-label {
    @include filter-label-selected;
  }
}

.p-icon-field-left > .p-inputtext {
  padding-left: nth($inputPadding, 2) * 2 + ($primeIconFontSize * 0.7);
}