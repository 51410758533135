@use 'sass:math';
@use '../themes/azz-theme/theme.scss' as *;

input, textarea, select {
  &:focus-visible {
    outline: none;
  }
}

input {
  &:autofill, &::-webkit-autofill {
    filter: none;
    background: transparent !important;
  }

  &:-webkit-autofill:focus {
    background: transparent !important;
  }

  &[disabled] {
    background: $disabledGray;
    color: $lightGray3;
  }

  &::placeholder {
    color: $darkPurple3;
    opacity: 1;
    font-size: rem(14);
    font-weight: 400;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

// forms 
.label-form {
  @extend %label;

  &.label-checkbox,
  &.label-radio {
    font-size: rem(14);
    color: $darkPurple2;
    line-height: 1.6;
    margin-bottom: 0 !important;
    cursor: pointer;
  }

  &.required {
    &:after {
      content: '*';
    }
  }
}

.label-container-hint {
  display: flex;
  align-items: center;
  gap: rem(4);
  margin-bottom: $labelMb;

  .label-form {
    margin-bottom: 0 !important; // temporary style, delete it after redesign
  }
  
  .azz-icon-info {
    display: flex;
  }
}

textarea {
  resize: none;
}

.error {
  color: $danger;
  font-size: rem(12);
  font-weight: 400;
}

// search input with icon search
.search-form {
  position: relative;
  &-icon {
    &.pi {
      font-size: rem(12) !important;
    }
  }

  &-input {
    padding-left: nth($inputPadding, 2) * 2 + ($primeIconFontSize * 0.7) !important;
  }

  &.autocomplete {
    .search-form-icon {
      position: absolute;
      top: rem(10);
      left: rem(10);
      z-index: 1;
    }
  }
}