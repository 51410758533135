.p-disabled,
.p-component:disabled {
  background: transparent;
  color: $lightGray3;
}

.p-disabled {
  .p-button {
    &:not(.p-button-outlined) {
      background: $disabledBtnGray !important;
    }
    border-color: $disabledBtnGray !important;
    color: $lightGray3 !important;
  }
}