.p-dialog {
  border-radius: $radiiXs;
  width: 100%;
  max-width: rem(400);
  box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.09);
  .p-dialog-header {
    .p-dialog-title {
      margin: 0 auto;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 0;
      right: 0;
    }

    .p-dialog-header-icon {
      &:hover {
        background: transparent;

        svg {
          opacity: $buttonStatesOpacity;
        }
      }
    }
  }

  p {
    font-weight: 400;
    font-size: rem(16);
  }

  .p-dialog-footer {
    display: flex;
    justify-content: center;
    border-radius: 0;

    button {
      margin: 0 rem(16) 0 0;
    }
  }
}