@use './_functions.scss' as *;

// standart colors
$white: #ffffff;
$black: #000000;
$black2: #202742;
$gray: #DDDDDD;

// common
$buttonStatesOpacity: 0.7;

// theme Specific Variables
$primaryColor: #4a33ec;
$primaryDarkColor: #1e88e5;
$primaryDarkerColor: #1976d2;
$primaryBlue: #2D75EB;
$primaryLightColor: #B9AFFF;
$primaryTextColor: #ffffff;
$focusVisibleColor: #607d8b;

// custom color variables
$defaultWhite: $white;
$darkPurple: #032860;
$darkPurple2: #11215A;
$darkPurple3: #41547F;
$purple: #0b3573;
$purpleGray: #606D90;
$purpleGray2: #6d7999;
$purpleGray3: #A7AFC0;
$lightGray: #E2E6E8;
$lightGray2: #eeeeee;
$lightGray3: #9099aa;
$lightPurple: #add3ff;
$lightPurple2: #F5F7FE;
$lightPurple3: #E4E7F3;
$disabledBtnGray: #DDE4E9;
$disabledGray: #EFF0F1;
$danger: #C10000;
$danger2: #FFA8B2;
$success: #00AE4A;
$success2: #A8F2A7;
$warning: #ED6300;
$warning2: #F2994A;
$warning3: #F9C75F;

// scroll
$scroll-bar: $purpleGray3;
$scroll-track: #EFF0F1;

// default
$bodyBg: $lightGray2;

// footer
$footerText: $purpleGray;
$footerTextAuth: $white;
$footerBg: $lightPurple2;

// header
$headerBg: $defaultWhite;

// custom sizes
$sidebarWidth: rem(96);
$headerHeight: rem(70);
$mobileHeaderHeight: rem(64);
$cardSecondaryPdY: rem(12);
$cardSecondaryPdX: rem(16);
$panelHeaderPdY: rem(20);
$panelHeaderPdX: rem(16);
$datepickerWidth: rem(326);
$datepickerMobileWidth: rem(290);
$sessionStatusSize: rem(18);
$sessionStatusSizeSm: rem(12);
$btnSquareSize: rem(32);

// spaces
$defaultPdX: rem(15);
$defaultPdY: rem(8);
$labelMb: rem(2);
$gapMd: rem(8);
$gapLg: rem(16);
$gapXl: rem(32);
$containerPdDesktop: rem(24);
$containerPdTablet: rem(16);
$modalPd: rem(32);

// radii
$radiiXs: 2px;
$radiiSm: 4px;
$radiiMd: 6px;
$radiiLg: 16px;
$radii2Lg: 18px;
$radiiXl: 23px;
$radiiPaginator: 50%;

// filters
$sizeIconFilter: rem(12);
$fontSizeLabelFilter: rem(12);
$fontSizeItemFilter: rem(14);
$fontWeightItemFilter: 400;
$fontWeightLabelFilter: 400;
$mobileSelectedFilterValue: 80vw;

// gradients
$primaryGradient: linear-gradient(to right, #4a30ec 0%, #199fea 100%);

$colors: (
  'blue': #4a33ec,
  'green': #a8f2a7,
  'yellow': #fbc02d,
  'cyan': #00bcd4,
  'pink': #ffa8b2,
  'indigo': #3f51b5,
  'teal': #009688,
  'orange': #f2994a,
  'bluegray': #607d8b,
  'purple': #9c27b0,
  'red': #c10000,
  'primary': $primaryColor
);

// Breakpoints
$desktopLg: 1260px;
$desktop: 1024px;
$tablet: 768px;
$mobile: 640px;

// container
$maxWidthContainer: 1344px;

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: '1.6rem';
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #{$black2};
  --text-color-secondary: #6c757d;
  --primary-color: #2196f3;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};
  color-scheme: light;
}
