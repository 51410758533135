.p-message {
  .p-message-detail {
    font-weight: 400;
    width: 100%;
    line-height: 1.2;
  }

  .p-message-close {
    &:hover {
      background: transparent;
      opacity: 0.5;
    }

    &:focus-visible {
      outline: 2px solid $focusVisibleColor;
    }
  }
}