@use '../themes/azz-theme/theme.scss' as *;

body {
  height: 100%;
  font-family: var(--font-family);
  font-size: var(--font-size);
  overflow-x: hidden;
  line-height: 1.4;
  &.no-scroll {
    touch-action: none;
    overscroll-behavior: contain;
    overflow: hidden;
  }

  * {
    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid $focusVisibleColor;
    }
  }
}

// scroll 
*::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.7rem;
}

*::-webkit-scrollbar-track {
  background: $scroll-track;
}

*::-webkit-scrollbar-thumb {
  background-color: $scroll-bar;
  border-radius: 10px;
  border: 2px solid $scroll-track;
}

@supports not selector(::-webkit-scrollbar) {
  * {
      scrollbar-width: thin;
      scrollbar-color: $scroll-bar $scroll-track;
  }
}

// layouts
.main {
  flex-grow: 1;
  min-height: inherit;
  margin: rem(10) auto;
}

.ng-content {
  background: $bodyBg; // temporary solution, delete after redesign
  height: 100%;
  min-height: 100vh;
}

.auth-content {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100vh;
  &-bg {
    background: url('/assets/images/auth-bg.png') no-repeat center;
    background-size: cover;
    overflow-y: auto;
  }
}

// atomic
.visibility-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
