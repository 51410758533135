.p-multiselect {
  &.filter {
    @include filter-input('.p-multiselect');
    .p-multiselect-header {
      display: none;
    }

    .p-multiselect-label {
      @include text-overflow(100%);
      font-weight: $fontWeightLabelFilter;
      max-width: rem(140);
      color: $darkPurple2;
      @media (max-width: $desktop) {
        max-width: $mobileSelectedFilterValue;
      }
    }
  }
}

p-multiselect {
  &.p-inputwrapper-filled {
    .p-multiselect.filter {
      background: $lightPurple;
      .p-inputtext {
        font-weight: $fontWeightItemFilter;
      }
  
      .p-multiselect-clear-icon,
      .icon-arrow {
        color: $darkPurple2;
      }

      .p-multiselect-clear-icon {
        top: rem(15);
      }
    }
  }
}

.p-multiselect-panel {
  @include dropdown-panel('.p-multiselect');
  .p-multiselect-items {
    padding-bottom: 0;
    .p-multiselect-item {
      padding: rem(8);
    }
  }
}