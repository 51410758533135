.p-tooltip {
  max-width: rem(300);
  .p-tooltip-text {
    border: 1px solid $lightGray;
    font-size: rem(14);
    font-weight: 400;
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      filter: drop-shadow(1px 0 0 $lightGray);
    }
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      filter: drop-shadow(-1px 0 0 $lightGray);
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      filter: drop-shadow(0 1px 0 $lightGray);
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      filter: drop-shadow(0 -1px 0 $lightGray);
    }
  }
}