/* TODO: delete bootstrap after redesign! */
@use 'sass:meta';

@layer old-styles, global, primeng, primeng-theme, primeng-flex, primeng-icon, bootstrap;

@import url('../../../node_modules/bootstrap/dist/css/bootstrap.min.css') layer(bootstrap);
@import url('../../../node_modules/primeng/resources/primeng.min.css') layer(primeng);
@import url('../../../node_modules/primeicons/primeicons.css') layer(primeng-icon);
@import url('../../../node_modules/primeflex/primeflex.css ') layer(primeng-flex);

@layer primeng-theme {
  @include meta.load-css('./styles.scss');
}

@layer global {
  @include meta.load-css('./global/global.scss');
}

// TODO: temporary solution
@layer old-styles {
  button {
    border-radius: 6px;
  }
}
